import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CgClose } from 'react-icons/cg'

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: ${props => props.open ? "rgba(100,100,100,0.5)" : "rgba(255,0,0,0)"};
    backdrop-filter: ${props => props.open ? "blur(10px)" : "blur(0px)"};
    z-index: 600;
    cursor:${props => props.open ? "pointer" : "default"};
    transition: all 1.5s linear, background-color 2s linear;
    `
const Window = styled.div`
    position: absolute;
    transition: all 0.25s ease-in-out;
    top: ${props => props.open ? "50%" : props.pos[ 1 ]};
    left: ${props => props.open ? "50%" : props.pos[ 0 ]};
    /* height: ${props => props.open ? "50%" : "0%"}; */
    width: ${props => props.open ? "100%" : "0%"};
    /* width:50%;
    height:50%;
    top:50%;
    left:50%; */
    transform: translate(-50%,-50%);
    /* background-color: white; */
    z-index: 100;
    border-radius: 15px;
    padding:3rem;
    & > div {
        position: relative;
        width:100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .big {
            font-size: 4rem;
        }
        .small {
            /* font-size:2rem; */
            font-size:4.4rem;
            font-weight:400;
            color:blue;
        }
        .link {
            cursor: pointer;
            margin: 2rem 1rem;
            &:hover {
                color:blue;
            }
        }
        & .icon {
            position: absolute;
            top:-2rem;
            right:-2rem;
        }
    }
`

function PopUp({onClick,active = true,pos,closeButton, setActive, children}) {

    const [ open, setopen ] = useState(true);

    useEffect(() => {
        if(!active) {
            setopen(false)
        }
    }, [active]);

    // const openClose = [
    //     { left: pos[ 0 ], top: pos[ 1 ], height: 0, width: 0 },
    //     { height: "100vh", width: "100vw" }
    // ]

    return (

        <Overlay open={open} pos={pos} onClick={() => onClick()}>
            <Window open={open} pos={pos}>
                <div>
                    {open ? children : null}
                    {closeButton ? 
                    <div className='icon'>
                        <CgClose size={40} onClick={() => setActive(false)} />
                    </div> : null}
                </div>
            </Window>
        </Overlay >

    )
}

export default PopUp