import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import ArtistMobile from './ArtistMobile';
// import { StateContext } from "./stateContext";
import ReactMarkdown from "react-markdown";
import MobileTicker from './MobileTicker';
// import {isMobile} from 'react-device-detect';

// const width = isMobile ? 60 : 40;

const AboutStyled = styled.div`
    /* font-family: 'Agipo mono', sans-serif; */
    /* font-family: 'Inter', sans-serif; */
    /* font-weight:300; */
   
    position: fixed;
    top:0;
    left:0;
    width:calc(100vw - 5rem);
    height:100vh ;
    /* background-color: rgba(0,255,0,0.8); */
    background-color: rgba(199, 255, 60, 0.95);
    padding:2.5rem ;
    overflow:scroll ;
    transition: right 0.5s ease-in-out, box-shadow 0.4s linear;
    backdrop-filter: blur(10px);
    color:black;

    & > * {
        transition: opacity 0.5s linear;
        opacity:${props => props.loaded ? "1" : "0"};
    }

    &:last-child {
        margin-bottom:30rem;
    }
    
    u {
        text-decoration:underline;
    }
    
    h1 {
        font-size:4.4rem;
        font-weight:300;
    }
    
    & > *:last-child {
        margin-bottom:30rem;
    }
    p {
        font-size:2.2rem;
    }
    .artist {

        p {white-space: break-spaces;}
        /* overflow:hidden; */
        scroll-margin:"60px";
        padding:1rem 0;

        
        h2{
            font-size:2.4rem;
        }
    }
    .about {
        white-space: break-spaces;
        img {
            width: 13.7rem;
        }

    }
    .intro {
        margin:2em 0 6em 0;

    }
    #logos {
        img {
            display:block;
            width:80%;

            height:auto;
            margin:10rem 0;
        }
    }
`


function MobileApp() {
    const [artistArray, setartistArray] = useState();
    const [aboutText, setaboutText] = useState();
    const [aboutIntro, setaboutIntro] = useState();
    const [colophon, setColophon] = useState();
    const [logos, setLogos] = useState();
    const [copyRight, setcopyRight] = useState();
    const [data, setdata] = useState();
    const [loaded, setloaded] = useState(false);
    const [mute, setMute] = useState(false);
    const [activeArtist, setactiveArtist] = useState();
    const [activeRef, setactiveRef] = useState();
    const [textDescription, setTextDescription] = useState(null);

    const scroller = useRef()

    function fetchArtists() {

        // return new Promise(resolve => {
        return fetch(`${process.env.REACT_APP_API_URL}/artists`)
          .then(response => response.json())
          .then(data => {
            return data
          })
      }
    
    function handleFetch() {
        // GET ALL FECTHED

          
        let artists = fetchArtists();
        Promise.all([ artists ]).then(resolve => {
            setdata(resolve[ 0 ])
            setTimeout(() => {
                setloaded(true)
                
            }, 600);
        })
    
    }

    useEffect(() => {
        if(data) {
            const namesComps = data.map((artist,index) =>{
                return (<ArtistMobile 
                            mute={mute} 
                            setMute={setMute} 
                            artist={artist} 
                            key={index} 
                            index={index}
                            activeArtist={index === activeArtist}
                            setActiveArtist={setactiveArtist}
                            setactiveRef={setactiveRef}
                            setTextDescription={setTextDescription}
                            loaded={loaded}
                    />)
            })
            setartistArray(namesComps)

        }
    }, [data, mute,activeArtist, loaded]);

    // useEffect(() => {
    //     if(activeArtist) {
    //         console.log(activeArtist * 35);
    //         scroller.current.scrollTo({
    //             top: (((activeArtist-1) * 80) + 335),
    //             left: 0,
    //             behavior: 'smooth'
    //           });
    //     }
    // }, [activeArtist]);

    useEffect(() => {
        if(activeRef) {
            setTimeout(() => {
                activeRef.current.scrollIntoView({block:"start",behavior:"smooth"})
                
            }, 500);
        }
    }, [activeRef]);

    useEffect(() => {
        const url = `${process.env.REACT_APP_API_URL}`
        fetch(`${url}about`)
            .then(data => data.json())
            .then((json) => {
                // console.log(json)
                setaboutText(<ReactMarkdown className='about'>{json.aboutText_MOBILE}</ReactMarkdown>)
                setaboutIntro(<ReactMarkdown className='about intro'>{json.about_mobile_intro}</ReactMarkdown>)
                
        })
        fetch(`${url}colophon`)
            .then(data => data.json())
            .then((json) => {
                console.log(json)
                const logos = json.logos.map(img => 
                    <img key={img.logo_png.id} src={`${url}/${img.logo_png.url}`} alt={img.logo_png.alternativeText} />
                )
                setcopyRight(<p>{json.copyright}</p>)
                setLogos(logos)
                setColophon(<ReactMarkdown className='about'>{json.colophon_MOBILE}</ReactMarkdown>)
                
        })
        handleFetch();

    

        // handleFader()




    }, [])



    return (
        <>
            <AboutStyled loaded={loaded} ref={scroller}>
                {/* <FiMoreHorizontal /> */}
                {/* <h1>ARTISTS</h1> */}
                {aboutIntro}
                {artistArray} {/* <Artist /> */}
                <h1>ABOUT</h1>
                {aboutText}
                <h1>COLOPHON</h1>
                {colophon}
                <div id="logos">
                    {logos}
                </div>
                {copyRight}
            </AboutStyled>
            {textDescription !== null && loaded ? 
                <MobileTicker text={textDescription} /> : null
            }
            {/* <ButtonMore zindex={zIndex} isactive={active} size={30} onClick={handleClick}/> */}
            {/* <ButtonClose zindex={zIndex} isactive={active} size={30} onClick={handleClick}/> */}
        </>
    );
}

export default MobileApp;