import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import { FiChevronRight } from 'react-icons/fi'
import ReactMarkdown from "react-markdown";

const Container = styled.div`
    max-height:${props => !props.active ? `${props.heightCollpased}px` : `${props.height + 100}px`} ;
    transition: max-height 0.5s ease-in-out;
    overflow:hidden;
    padding-left:4px;
     p {
        white-space: break-spaces;
     }
`

const Expander = styled.div`
    max-height:${props => !props.active ? "0px" : `${props.height}px`} ;
    transition: all 0.5s ease-in-out;
    margin-top:1rem;
    margin-bottom:${props => !props.active ? "0px" : "5rem"};
/*     
    p, h2{
        font-size: 69%;
        font-family: "Agipo";
        font-weight: 400;
        letter-spacing: 0.2px;
        line-height: 1.21;
    } */

    /* h2 {
        font-weight:400 ;
        font-size:3rem;
    } */

`

const Arrow  = styled(FiChevronRight)`
    transform:${props => props.active ? "rotate(90deg)" : "rotate(0deg)"};
    transition:transform 0.1s linear;
`
const Name = styled.div`
    display: flex;
    flex-direction:row;
    align-items: center ;
    h1 {
        cursor:pointer; 
        margin: 0.8rem 0;
        font-style:${props => props.active ? "italic" : "normal"} ;
        &:hover {
            font-weight: 400;
        }
    } 

`

function Artist(props) {

    const {artist,setPrePos, isResizing} = props

    const [active, setactive] = useState(true);
    const [height, setheight] = useState();
    const [nameHeight, setNameHeight] = useState();
    // const [localPrePos, setlocalPrePos] = useState({});
    const ref = useRef();
    const nameRef = useRef();

    const zeroPos = []
    // const letterPositions = []
    for (let index = 0; index < 25; index++) {
        zeroPos[index] = 0;
    }
    // console.log(artist)



    //GET ALL LETTERINDEX that has content for this artist
    const arr = [artist.audioTrack.letterIndex, ...artist.webWorks.map(work => work.letterIndex)]

    const filterArr = [...new Set(arr)] //REMOVE DUPLICATES METHOD
    
    const letterPositions = [...zeroPos]
    // console.log(filterArr);2
    // for (let index = 0; index < 25; index++) {
    //     letterPositions[index] = 0;
    // }
    const posObj = {}
    const zeroPosObj = {}
    //     index:val

    filterArr.forEach(letterIndex => {
        //translate to fit letterindex map (0-25)
        const index = letterIndex > 24 ? letterIndex - 25 : letterIndex;
        // give a plus minus value (up/down) value for the faders
        const val = letterIndex > 24 ? 0.85 : -0.85;
        letterPositions[index] = val;
        posObj[index] = val
        zeroPosObj[index] = 0
        // console.dir(posObj);

    })

    // console.log(posObj);
    // console.log(filterArr,letterPositions)
    // setlocalPrePos(letterPositions)
    // setlocalPrePos(posObj)
    useEffect(() => {
        
        const p = ref.current.getBoundingClientRect()
        const n = nameRef.current.getBoundingClientRect()
        // console.log(p.height)
        setheight(p.height)
        setNameHeight(n.height)
        // ref.current.classList.add("active")
        setactive(false)
    }, []);
    useEffect(() => {
        
        // const p = ref.current.getBoundingClientRect()
        const n = nameRef.current.getBoundingClientRect()
        // console.log(p.height)
        // setheight(p.height)
        setNameHeight(n.height)
        // ref.current.classList.add("active")
        // setactive(false)
    }, [isResizing]);
    
    const handleClick = () => {
        setactive(!active)
        
        if(active) { //active will still be read in its old state
            setPrePos(zeroPosObj)
        } else {
            setPrePos(posObj)
        }
    }

    return ( 
        <Container height={height} heightCollpased={nameHeight} active={active}>
            
            <Name active={active} ref={nameRef}>
                {/* <Arrow size={25}active={active}/> */}
                <h1 onClick={handleClick}>{artist.name} </h1>

            </Name>
            <Expander ref={ref} height={height} active={active}>
                {artist.aboutWork ? 
                <>
                    {/* <h2>About</h2> */}
                    <ReactMarkdown>{artist.aboutWork}</ReactMarkdown>
                </> :
                null}
                <h2>Bio</h2>
                <ReactMarkdown>{artist.bio}</ReactMarkdown>
                {artist.credits ? 
                <>
                    <h2>Credits</h2>
                    <ReactMarkdown>{artist.credits}</ReactMarkdown>
                </> 
                : null}

            </Expander>
        </Container>
    );
}

export default Artist;