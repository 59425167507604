import React, { useState, useEffect, useContext, useRef } from 'react';
import styled from 'styled-components';
import Artist from './Artist';
import { FiMoreHorizontal, FiX } from 'react-icons/fi'
import { StateContext } from "./stateContext";
import ReactMarkdown from "react-markdown";
import sound from '../media/2sBlank.mp3'
// import {isMobile} from 'react-device-detect';

// const width = isMobile ? 60 : 40;

const AboutStyled = styled.div`
    /* font-family: 'Agipo mono', sans-serif; */
    /* font-family: 'Inter', sans-serif; */
    /* font-weight:300; */
    position: fixed;
    top:0;
    right:${props=> props.isactive ? "0" : `calc(-40vw - 10rem)`};
    width:${`40vw`};
    /* min-width:640px; */
    /* ${props=> props.isactive ? "min-width:600px;" : null} */
    height:100vh ;
    /* background-color: rgba(0,255,0,0.9); */
    background-color: rgba(199, 255, 60, 0.95);
    z-index:${props => props.zindex} ;
    padding:5rem;
    overflow:scroll ;
    transition: right 0.5s ease-in-out, box-shadow 0.4s linear;
    backdrop-filter: blur(10px);
    color:black;
    box-shadow:${props=> props.isactive ? "-5px 0px 11px 5px #0000002e" :  "0px 0px 0px 0px #0000002e"};
    
    u {
        text-decoration:underline;
    }
    
    h1 {
        font-size:4.4rem;
        font-weight:400;
        /* word-break:none;
        white-space:nowrap ; */
    }
    
    & > *:last-child {
        margin-bottom:10rem;
    }
    p, h2 {
        font-size:2.1rem;
        /* font-size: 69%; */
        font-family: "Agipo";
        font-weight: 400;
        letter-spacing: 0.2px;
        line-height: 1.21;
    }
    .artist {
        p {white-space: break-spaces;}
        overflow:hidden;
        
        h2{
            font-size:1.8rem;
        }
    }
    .about {
        margin-top:10rem;
        white-space: break-spaces;
        img {
            width: 13.7rem;
        }

    }
    .colophon {
        white-space: break-spaces;
    }
    #logos {
        img {
            display:block;
            width:25%;
            height:auto;
            margin:4rem 0;
        }
    }
    a {
        color:black;
    }
`

const ButtonMore = styled(FiMoreHorizontal)`
    position: fixed;
    top: 2.5rem;
    right: 2.5rem;
    color: black;
    cursor:pointer;
    z-index:${props => props.zindex} ;
    opacity: ${props => props.isactive ? "0" : "1"};
    transform: ${props => props.isactive ? "rotate(270deg) scale(1)" : "rotate(0deg) scale(1)"};
    transition: all 0.4s ease-in-out;
    `
const ButtonClose = styled(FiX)`
    position: fixed;
    top: 2.5rem;
    right: 2.5rem;
    color: black;
    cursor:pointer;
    /* color:red; */
    z-index:${props => props.zindex};
    opacity: ${props => !props.isactive ? "0" : "1"};
    transform: ${props => props.isactive ? "rotate(270deg)" : "rotate(0deg)"};
    transition: all 0.4s ease-in-out;
`



function About(props) {
    const zLevel = 15;
    const {data, letterActive} = props
    const [artistArray, setartistArray] = useState();
    const [active, setActive] = useState(false);
    const [zIndex, setzIndex] = useState();
    const {globalZ, setHasChanged} = useContext(StateContext)
    const [addObj, setaddObj] = useState();
    const [aboutText, setaboutText] = useState();
    const [colophon, setColophon] = useState();
    const [logos, setLogos] = useState();
    const [copyRight, setcopyRight] = useState();
    const [isResizing, setisResizing] = useState(0);
    const [combinedAboutPositions, setcombinedAboutPositions] = useState();

    const audio = useRef()

    useEffect(() => {
        if(data) {
            const namesComps = data.map((artist,index) =>{
                return (<Artist isResizing={isResizing} artist={artist} key={index} setPrePos={handlePrePos}/>)
            })
            setartistArray(namesComps)

        }
    }, [data,isResizing]);

    window.addEventListener('resize', (e) => {
        console.log(e.timeStamp);
        setisResizing(e.timeStamp)
    })
    

    useEffect(() => {
        // const url = "http://triple.jsmdev.xyz:1337/uploads/IMG_5379_212d808a1f.JPG"
        const url = `${process.env.REACT_APP_API_URL}`
        // console.log(props.url)
        fetch(`${url}/about`)
            .then(data => data.json())
            .then((json) => {
                // console.log(json)
                setaboutText(<ReactMarkdown className='about'>{json.aboutText}</ReactMarkdown>)
                
        })
        fetch(`${url}/colophon`)
            .then(data => data.json())
            .then((json) => {
                console.log(json)
                const logos = json.logos.map(img => 
                    <img key={img.logo_png.id} src={`${url}/${img.logo_png.url}`} alt={img.logo_png.alternativeText} />
                )
                setcopyRight(<p>{json.copyright}</p>)
                setLogos(logos)
                setColophon(<ReactMarkdown className='colophon'>{json.colophon}</ReactMarkdown>)
                
        })

    

        // handleFader()




    }, [])

//     useEffect(() => {
//         const zeroPos = []
// const letterPositions = []
        // for (let index = 0; index < 25; index++) {
        //     zeroPos[index] = 0;
        // }
//         setcombinedAboutPositions(zeroPos)
//         console.log("set to zero");
//     }, []);

    useEffect(() => {
        setActive(false)
    }, [letterActive]);
    useEffect(() => {
        setzIndex(globalZ + zLevel)
    }, [globalZ]);

    const handleClick = () => {
        setActive(!active)
        audio.current.play()

    }

    //Set the obj to add to combined pos
    const handlePrePos = (posObj) => {
        setaddObj(posObj)
    }

    //when a new add obj is set add them to combined positions
    useEffect(() => {
        if(addObj) {
            addToCombinedPositions()
            setHasChanged(true)
        }
    }, [addObj]);

    //handle the add to combined positions
    const addToCombinedPositions = () => {
        let newPositions = [];
        if(!combinedAboutPositions) {
            // console.log("zeroing positions");
            for (let index = 0; index < 25; index++) {
                newPositions[index] = 0;
            }
        } else {
            newPositions = [...combinedAboutPositions]
        }
        // console.log(combinedAboutPositions);

        Object.keys(addObj).forEach(posKey => {
            const index = parseInt(posKey)
            newPositions[index] = addObj[posKey]
        })
        // console.log(newPositions);
        setcombinedAboutPositions(newPositions)
    }
    //set the new postions to the letters 
    useEffect(() => {
        props.setPrePos(combinedAboutPositions)
        
    }, [combinedAboutPositions]);

    console.log(`hidden audio ${sound}`);

    return (
        <>
            <AboutStyled isactive={active} zindex={zIndex}>
                {/* <FiMoreHorizontal /> */}
                {/* <h1>ARTISTS</h1> */}
                {artistArray} {/* <Artist /> */}
                {/* <h1>ABOUT</h1> */}
                {aboutText}
                <h1>Colophon</h1>
                {colophon}
                <div id="logos">
                    {logos}
                </div>
                {copyRight}
            </AboutStyled>
            <ButtonMore zindex={zIndex} isactive={active} size={40} onClick={handleClick}/>
            <ButtonClose zindex={zIndex} isactive={active} size={40} onClick={handleClick}/>
            <audio src={sound} ref={audio}></audio>
        </>
    );
}

export default About;