import React, {useState, useRef, useEffect} from 'react';
import styled from 'styled-components';
import { FiChevronRight } from 'react-icons/fi'
import { HiVolumeUp,HiVolumeOff } from "react-icons/hi";
import ReactMarkdown from "react-markdown";
import MobileTicker from './MobileTicker';
const Expander = styled.p`
    max-height:${props => !props.active ? "0px" : `${props.height}px`} ;
    transition: all 0.5s ease-in-out;
    margin-bottom:${props => !props.active ? "0px" : "5rem"};
    overflow:hidden ;
    h2 {
        /* font-weight:400 ; */
    }

`

const Arrow  = styled(FiChevronRight)`
    transform:${props => props.active ? "rotate(90deg)" : "rotate(0deg)"};
    transition:transform 0.1s linear;
`
const Name = styled.div`
    display: flex;
    flex-direction:row;
    align-items: center ;
    justify-content:space-between ;
    h1 {
        cursor:pointer; 
        margin: 0.5rem 0;
        font-size:4.4rem;
        font-style:${props => props.active ? "italic" : "normal"} ;
        /* font-weight:${props => props.active ? "400" : "light"}; */
        span {
            font-size:1.9rem;
            font-style:normal;
            letter-spacing:normal;
        }
    } 

`

function Artist(props) {

    const {loaded,setTextDescription, artist, mute, setMute, setActiveArtist, activeArtist,index,setactiveRef} = props

    const trackUrl = artist.audioTrack.mp3.url

    const [active, setactive] = useState(true);
    const [height, setheight] = useState();
    const [trackLength, settrackLength] = useState();
    // const [localPrePos, setlocalPrePos] = useState({});
    const ref = useRef();
    const containerRef = useRef()
    const audio = useRef();

    const zeroPos = []
    // const letterPositions = []
    for (let index = 0; index < 25; index++) {
        zeroPos[index] = 0;
    }
    // console.log(artist)

    // console.log(artist.audioTrack);

    useEffect(() => {
        
        const p = ref.current.getBoundingClientRect()
        // console.log(p.height)
        setheight(p.height)
        // ref.current.classList.add("active")
        setactive(activeArtist)
    }, []);
    
    useEffect(() => {
        setactive(activeArtist)
        
        if(!activeArtist) {
            audio.current.pause()
            // setTextDescription(null)
        }
    }, [activeArtist]);

    useEffect(() => {
        // console.log(audio.current);
        if(audio.current) {
            audio.current.onloadedmetadata = e => {
                // console.log(e);
                const SECONDS = audio.current.duration
                // console.log(SECONDS)
                const time = new Date(SECONDS * 1000).toISOString().slice(14,-5);
                settrackLength(time)
            }
        };
    }, [audio]);

    



    const handleClick = () => {
        setactive(!active)
        setActiveArtist(index)
        setactiveRef(containerRef)
        if(active) {
            audio.current.pause()
            // setTextDescription(null)
        } else {
            
            if(!mute) {
                // setTextDescription(artist.audioTrack.textDescription)
                audio.current.play()
            }
        }
        // if(active) { //active will still be read in its old state
        //     setPrePos(zeroPosObj)
        // } else {
        //     setPrePos(posObj)
        // }
    }
    useEffect(() => {
        let wait;
        if(active && loaded) {

            wait = setTimeout(() => {
                setTextDescription(artist.audioTrack.textDescription)
                
            }, 100);
        } else {
             setTextDescription(null)
             clearTimeout(wait)
        }
    }, [active, loaded]);
    
    function playPause() {
        if(audio.current.paused) {
            audio.current.play()
        } else {
            audio.current.pause()
        }
    }
    function handleMute() {
        setMute(!mute)
        playPause()
    }

    return ( 
        <div className='artist' ref={containerRef} style={{ scrollMargin:"60px"}}>
            
            <Name active={active}>
                {/* <Arrow size={25}active={active}/> */}
                <h1 onClick={handleClick}>{artist.name} – {artist.audioTrack.title} <span>{trackLength}</span> </h1>
                {active ? 
                <>
                    <div id='mute' onClick={() => handleMute()}>{!mute ? <HiVolumeUp size={25}/> : <HiVolumeOff size={25}/>}</div>
                </>: null}

            </Name>
            <Expander ref={ref} height={height} active={active}>
                {artist.aboutWork ? 
                <>
                    <h2>About</h2>
                    <ReactMarkdown>{artist.aboutWork}</ReactMarkdown>
                </> :
                null}
                <h2>Bio</h2>
                <ReactMarkdown>{artist.bio}</ReactMarkdown>
                {artist.credits ? 
                <>
                    <h2>Credits</h2>
                    <ReactMarkdown>{artist.credits}</ReactMarkdown>
                </> 
                : null}

            </Expander>
            <audio
                    style={{ display: "none", position: "fixed" }}
                    loop
                    // onPlaying={() => { setPlayState("playing") }}
                    // onPause={() => { setPlayState("stopped") }}
                    ref={audio}
                    controls src={`${process.env.REACT_APP_API_URL}/${trackUrl}`}
                    type="audio/mpeg"
                    autoPlay={false}
                    preload="metadata" 
                    // volume={volume}
                    // data-isplaying={props.hasStartPos}
                >
            </audio>
            
        </div>
    );
}

export default Artist;