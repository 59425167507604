import React, { useContext, useState, useEffect } from 'react';
import { StateContext } from './stateContext';
import styled from 'styled-components';


const Container = styled.div`
    position: absolute;
    top:2.5rem;
    left:2.5rem;
    z-index:100000 ;
    color:white;
    background-color:#6e6e6e54;
    padding:${props => !props.active && props.hasChanged ? "0.8rem 2rem" : "0"};
    font-size:1.7rem;
    border-radius:20px;
    transition:max-width 0.5s ease-in-out;
    max-width:${props => props.active ? "80rem" : "5rem"};
    overflow:hidden ;
    &:hover {
        cursor: pointer;
    }
   white-space:nowrap;
   .link {
        padding:0.8rem 2rem;
        color:blue;
        user-select:text;
        &:hover {
            text-decoration:underline ;
        }
   }
   box-shadow: inset -1px 6px 14px 0px #ffffff80;

   /* box-shadow:inset 1px 4px 4px 0px #ffffff80; */

`

const ShareButton = (props) => {
    const { setShareId, setHasChanged, hasChanged } = useContext(StateContext);
    const [hasSaved, setHasSaved] = useState(false)
    const [id, setId] = useState()


    
    useEffect(()=> {
        setId(getRandomString(12))
        setHasChanged(false)
        return () => {
        }
    },[])

    useEffect(()=>{
        console.log(`hasChanged:${hasChanged}`);
        if(hasChanged) {
            props.setActive(false)
        }
    }, [hasChanged])

    function getRandomString(length) {
        var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var result = '';
        for ( var i = 0; i < length; i++ ) {
            result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
        }
        return result;
    }

    //SELECT AND COPY
    const handleClick = (e) => {
        if(!hasSaved) {
            setShareId(id)
            setHasSaved(true)
        }
        const text = e.target.innerHTML;
        const node = e.target;

        console.log(window.isSecureContext)
        // if (document.body.createTextRange) {
        //     const range = document.body.createTextRange();
        //     range.moveToElementText(node);
        //     range.select();
        // } else if (window.getSelection) {
        //     const selection = window.getSelection();
        //     const range = document.createRange();
        //     range.selectNodeContents(node);
        //     selection.removeAllRanges();
        //     selection.addRange(range);
        // }
        // const selection = window.getSelection();
        if(window.isSecureContext) {
            navigator.clipboard.writeText(text)
            // document.execCommand("Copy")
            // selection.removeAllRanges();
            // console.log("Copied!");
            node.innerHTML = "Copied!"
            setTimeout(() => { 
                // node.innerHTML = text 
                props.setActive(false)
            }, 1000);

        }

    }

    return(
        <>
        {hasChanged ? 
        <span>Share link</span>
        :
        <p className="small link" onClick={handleClick}>{window.location.origin}?share={id}</p>
        }
        </>
    )
}


const Share = (props) => {

    const [ active, setActive ] = useState(false);
    const [ pos, setPos ] = useState([ 0, 0 ])
    const {setHasChanged, hasChanged } = useContext(StateContext);

    return (
        <React.Fragment>
                <Container onClick={()=> {setActive(true);setHasChanged(false)}} active={active} hasChanged={hasChanged}>
                        {active ? 
                            <ShareButton setActive={setActive}/>
                            :
                            <>
                            {hasChanged ?
                                <span>Share</span>
                            : null
                            }
                            </>
                        }

                    {/* <FiShare size={30} onClick={(e) => { setPos([ `${e.clientX}px`, `${e.clientY}px` ]); setActive(true) }} /> */}
                </Container>

        </React.Fragment>


    )
}

export default Share