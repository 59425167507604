import React, { useEffect, useState, useContext } from 'react';
import Image from './Image.js';
import AudioTrack from './AudioTrack.js';
import Video from './Video.js';
import Text from './Text.js';
import {isMobile} from 'react-device-detect';
// import seed from 'random-seed';
import { StateContext } from "./stateContext"

function Letter(props) {
    const { preComp, recPos, setPositions, setHasChanged, hasChanged, globalZ,setGlobalZ } = useContext(StateContext);

    const [ pos, setPos ] = useState(props.winHeight / 2);
    const [ posOrg, setPosOrg ] = useState(props.winHeight / 2);
    const [ str, setStr ] = useState();
    const [ isSpace, setIsSpace ] = useState(false);
    const [ fader, setFader ] = useState(0);
    const [ direction, setDirection ] = useState(0) //1 up -1 down
    // const [ snap, setSnap ] = useState(false);
    const [ contentComps, setContentComps ] = useState([]);
    const [ winHeight, setWinHeight ] = useState(props.winHeight);
    const [ elHeight, setElHeight ] = useState(null)
    // const [ hasStartPos, setHasStartPos ] = useState(false)
    const [ moving, setMoving ] = useState(false)
    // const [ hasMoved, setHasMoved ] = useState(false);
    const [ clientY, setClientY ] = useState();
    const [ charRef ] = useState(React.createRef());
    const [ localZ, setLocalZ ] = useState(0);
    const [letterZ, setletterZ] = useState(1);

    const [mediaRef, setMediaRef] = useState();
    // window.onresize = ()=> {
    //     setWinHeight(window.innerHeight);
    // }

    // console.log(props.index)

    // Send Position to StateContext
    useEffect(() => {
        // console.log(recPos)
        if (recPos) {
            // console.log(`index:${props.index}, pos:${fader}`)
            setPositions(props.index, fader)
        }
    }, [ recPos ]);

    useEffect(() => {
        setletterZ(globalZ + 1)
    }, [globalZ]);

    // Window resize event:
    useEffect(() => {
        setPosOrg(props.winHeight / 2)

        //if letter is centered move to new center:
        if (pos === posOrg) {
            setPos(props.winHeight / 2)
        } else {
            //do handlefade() here with adjusted pos

            const precentagePos = pos / winHeight;

            const newPos = precentagePos * props.winHeight;

            // console.log(newPos);

            // handleFader(newPos, elHeight);

            const totalHeight = window.innerHeight - (2 * elHeight);
            const percent = ((newPos - (props.winHeight / 2)) / totalHeight) * 2;

            setFader(percent);
            // console.log(`precent:${percent},posOrg:${(props.winHeight / 2)}, index:${props.index}`)

            setPos(newPos);


        }
        setWinHeight(props.winHeight)
    }, [ props.winHeight ])


    //DEFINE SMALL LETTERS AND SPACES
    useEffect(() => {
        // console.log("small check");
        setStr(props.char)
        // console.log(props.startPos);
        const smallLetters = [ "i" ];
        if (props.char === " " || smallLetters.includes(props.char)) {
            setIsSpace(true);
        }
        // random seed syntax
        // let rand = seed.create("seed");
        setTimeout(() => {
            setElHeight(charRef.current.clientHeight)
        }, 100);

    }, [])


    //UPDATE CONTENT -- send newDirection to this when loading preset positions

    function updateContent(currDirection = direction) {

        const { index, data } = props
        if (data) { // && props.data.webWorks
            // console.log(props.data)
            const content = []

            // console.log(fader);

            data.forEach((el, i) => {
                if (el.mp3) {
                    content.push(
                        <AudioTrack
                            fader={fader}
                            down={el.letterIndex > 24 ? -1 : 1} // -1 is down 1 is up
                            hash={el.mp3.hash}
                            track={el.mp3.url}
                            letterIndex={el.letterIndex}
                            description={el.textDescription}
                            direction={currDirection}
                            key={`aud${el.id}_${i}`}
                            id={index}
                            moving={moving}
                            // hasStartPos={hasStartPos}
                            char={props.char}
                            mediaRef={setMediaRef}
                        // onVinyl={el}
                        />)
                }
                if (el.image) {
                    const placement = el.letterIndex > 24 ? -1 : 1
                    content.push(
                        <Image
                            key={`img${el.id}_${i}`}
                            letterIndex={el.letterIndex}
                            down={el.letterIndex > 24 ? -1 : 1}
                            // snap={snap}
                            fader={fader}
                            direction={currDirection}
                            // seed={'randomseed'}
                            url={el.image.url}
                            style={{
                                top: `${el.top}%`,
                                left: `${el.left}%`,
                                width: `${el.width}%`,
                                zIndex:`${localZ}`,
                                // display:`${placement === currDirection && fader !== 0 ? "block" : "none"}`
                            }}
                            // placement={i < 1 ? -1 : 1}
                            // hasStartPos={hasStartPos}
                        />
                    )
                }
                if (el.videoFile && !isMobile) {
                    content.push(
                        <Video
                            mediaRef={setMediaRef}
                            char={props.char}
                            down={el.letterIndex > 24 ? -1 : 1}
                            letterIndex={el.letterIndex}
                            key={`vid${el.id}_${i}`}
                            fader={fader}
                            volumeControl={el.volumeControl !== null ? el.volumeControl : 1}
                            // snap={snap}
                            direction={currDirection}
                            // seed={'randomseed'}
                            url={el.videoFile}
                            style={{
                                top: `${el.top}%`,
                                left: `${el.left}%`,
                                width: `${el.width}%`,
                                zIndex:`${localZ}`
                            }}
                        />
                    )
                }
                if (el.textWork) {
                    content.push(
                        <Text
                            char={props.char}
                            down={el.letterIndex > 24 ? -1 : 1}
                            letterIndex={el.letterIndex}
                            key={el.id}
                            fader={fader}
                            // snap={snap}
                            direction={currDirection}
                            text={el.textWork}
                            // seed={'randomseed'}
                            style={{
                                // top: `${el.top}%`,
                                // left: `${el.left}%`,
                                // width: `${el.width}%`,
                                zIndex:`${localZ}`
                            }}
                        />
                    )
                }
            })

            setContentComps(content);
        }
    }
    useEffect(() => {
        updateContent();
        // console.log(fader)

    }, [ props.data, fader ]);

    function handleTouchStart(event) {

        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchUp);
        if (!hasChanged) {
            setHasChanged(true)
        }
        if(globalZ !== localZ) {
            const newZ = globalZ + 1;
            setLocalZ(newZ)
            setGlobalZ(newZ)
    
        }
        // console.log(hasMoved);
        charRef.current.parentNode.classList.remove("transition")
    }


    function handleMouseDown(event) {
        window.addEventListener('mousemove', handleMove);
        window.addEventListener('mouseup', handleUp);
        if (!hasChanged) {
            setHasChanged(true)
        }
        if(globalZ !== localZ) {
            const newZ = globalZ + 1;
            setLocalZ(newZ)
            setGlobalZ(newZ)

        }
        // console.log(hasMoved);
        if(mediaRef) {
            mediaRef.volume = Math.abs(fader) <= 1 ? Math.abs(fader) : 1;
            mediaRef.play()
        }
        charRef.current.parentNode.classList.remove("transition")

    }

    function handleMouseLeave(e) {
        window.removeEventListener('mousemove', handleMove);
        // props.setPos(props.index, fader);
    }
    function handleTouchUp() {

        window.removeEventListener('touchmove', handleTouchMove);
        // window.removeEventListener('mousedown', handleMouseDown)
        window.removeEventListener('touchend', handleTouchUp)
        setMoving(false)
        charRef.current.parentNode.classList.add("transition")

    }
    function handleUp() {

        window.removeEventListener('mousemove', handleMove);
        // window.removeEventListener('mousedown', handleMouseDown)
        window.removeEventListener('mouseup', handleUp)
        setMoving(false)
        charRef.current.parentNode.classList.add("transition")

    }

    function handleTouchMove(e) {
        setClientY(e.targetTouches[0].clientY)

    }

    function handleMove(e) {
        // console.log(e);
        setClientY(e.clientY)
    }

    useEffect(() => {
        // setHasMoved(true);
        move(clientY)
    }, [ clientY ]);

    function move(e) {
        //distance from orginal position
        setMoving(true);
        const bounds = Math.abs((props.winHeight / 2) - e);
        // console.log(`bounds${bounds}, elHeigt:${elHeight}`)

        //check if above or under org pos
        const up = e > (props.winHeight / 2) ? false : true;

        const newDirection = up ? 1 : -1;
        setDirection(newDirection);
        // setElHeight(elHeight);

        //SET SOME SNAPPING:

        //far away from center... follow mouse
        if (bounds > 10) {
            handleFader(e, elHeight);
        }
        //reaching top or bottom of screen -> snap
        if (bounds > ((props.winHeight / 2) - (0.5 * elHeight))) {
            const snap = up ? (0.5 * elHeight) : window.innerHeight - (0.5 * elHeight);
            handleFader(snap, elHeight);
            // setSnap(1);
        }

        //reaching org pos -> go to posOrg
        if (bounds < 10) {
            handleFader((props.winHeight / 2), elHeight);
            // setSnap(-1);
        }

        // console.log(elHeight)

    }
    function handleFader(y, height) {
        const totalHeight = winHeight - height;
        // const totalHeight = window.innerHeight - (2 * height);
        const percent = ((y - (winHeight / 2)) / totalHeight) * 2;
        // const percent = ((y - (winHeight / 2)) / totalHeight) * 2;
        // const percent = ((y - (winHeight)) / winHeight);
        const actualPercent = (y / winHeight);
        setFader(percent);

        setPos(y);
        // const calcY = ((((percent / 2) * totalHeight) * 2) + props.winHeight) / 2
        // console.log(y, percent, winHeight, calcY);
        // updateContent();
    }


    // SET FADERS TO STARTPOS
    useEffect(() => {
        if (preComp) {
            // const clippedPos = Math.abs(props.startPos) > 1 ? 1 : props.startPos
            // const totalHeight = window.innerHeight - (2 * elHeight);
            // const percentToY = ((((((props.startPos / 2) * totalHeight) * 2) + props.winHeight) / 2) + (elHeight / 2))
            const totalHeight = winHeight - charRef.current.clientHeight;
            const percentToY = Math.round(((((props.startPos * totalHeight) + props.winHeight) / 2)))
            const mid = Math.round(props.winHeight / 2);
            const up = percentToY > (props.winHeight / 2) ? false : true;
            const newDirection = up ? 1 : -1;
            
            // console.log("precomp detected",newDirection, props.char);
            setDirection(newDirection)
            console.log( percentToY, mid, newDirection);
            
            // //GOING UP
            // if(mid > percentToY) {
            //     // console.log(pos);
                
            //     for (let index = mid; index > percentToY; index--) {
            //         // console.log(index);
            //         setTimeout(() => {
                        
            //             move(index)
            //         }, 10);
            //     }


            // }
            // //GOING DOWN
            // console.log(pos);
            // if(mid < percentToY) {
                
            //     for (let index = mid; index < percentToY; index++) {
            //         // console.log(index);
            //         setTimeout(() => {
                        
            //             move(index)
            //         }, 10);
            //     }
            // }

            // for (let index = Math.round(winHeight / 2); index < Math.round(percentToY); index++) {
            //     const element = array[index];
                
            // }
            setFader(props.startPos)
            setPos(percentToY)

            //Force it to use the auto generated direction value
            updateContent(newDirection);

        }
    }, [ preComp ]);


    return (
        <React.Fragment>
            <div className={`transition ${isSpace ? "space" : "letter"}`} fader={fader} pos={pos}>
                {str !== " " ?
                    <p
                        className={`${contentComps.length > 0 ? "hasContent" : "noContent"}`}
                        ref={charRef}
                        style={{ top: pos, zIndex:`${letterZ}` }}
                        onMouseDown={handleMouseDown}
                        onMouseLeave={handleMouseLeave}
                        onTouchStart={handleTouchStart}
                        onTouchEnd={handleMouseLeave}
                        
                    >
                        {str}
                        {/* {localZ} */}
                        {/* <span style={{ fontSize: "9px" }}>{fader}</span> */}
                    </p> : <p ref={charRef} style={{ top: pos }} ></p>}

                {contentComps}

            </div>

        </React.Fragment>
    )
}

export default Letter;