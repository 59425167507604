import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import StateContextProvider from "./components/stateContext.js";
// import Subtitle from "./components/Subtitle.js";
import queryString from 'query-string'
import MobileApp from './components/MobileApp';
import {isMobile} from 'react-device-detect';

window.addEventListener('DOMContentLoaded', (event) => {
  console.log('DOM fully loaded and parsed');
});
// const [ letterPositions, setLetterPositions ] = useState({ go: "GO", 22: "yo" });
const share = queryString.parse(window.location.search);
console.log(share);

// const isMobile = true;

ReactDOM.render(
  <StateContextProvider>
    {isMobile ? 
        <MobileApp />
      : 
        <App share={share.share ? true : false} compID={share.share} />
    }
  </StateContextProvider>,
  document.getElementById('root')
);
