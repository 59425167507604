import React, { useEffect, useState, useContext } from 'react';
import './App.scss';
import Letter from './components/Letter.js';
import PopUp from './components/PopUp';
import Share from './components/Share.js'
import About from './components/About';
import { StateContext } from "./components/stateContext";
function App(props) {

  // console.log(window.innerHeight)
  const [ winHeight, setWinHeight ] = useState(window.innerHeight);
  const lettersString = "triple pocket napkin fold" //lenght 25
  const lettersArr = Array.from(lettersString);

  const [ letterComps, setLetterComps ] = useState([]); //MAKE LOADING COMP HERE
  const [ prePopUp, setPrePopUp ] = useState()
  const [ popUpActive, setPopUpActive ] = useState()
  const { setPreComp, globalZ } = useContext(StateContext);
  const [allArtists, setAllArtists] = useState();
  const [aboutActive, setAboutActive] = useState();
  const [zIndex, setzIndex] = useState();
  const [prePos, setPrePos] = useState();

  let count = 11; //11*10px = 110px / 25 = 

  const zLevel = 12;
  const letterWidth = 100 / lettersArr.length;

  document.documentElement.style.setProperty("--letter-width", `calc(${letterWidth}% + ${(count * 1.5) / lettersString.length}rem)`)


  useEffect(() => {
    handleFetch()
  }, [ winHeight ]);

  useEffect(() => {
    setzIndex(globalZ + zLevel)
}, [globalZ]);

  useEffect(() => {
    // setLetterPositions({})
    // console.log(props);

    setPrePopUp(props.share ? true : false)
    setPopUpActive(props.share ? true : false)
    setzIndex(globalZ + zLevel)

  }, []);

  // useEffect(() => {
  //   console.log(allArtists)
  // }, [allArtists]);

  
  window.onresize = () => {
    setWinHeight(window.innerHeight);
    // console.log(winHeight/2);
  }


  // FETCH WITH PROMiSE
  function fetchPrePos() {
    // return new Promise(resolve => {
    // return fetch(`http://triple.jsmdev.xyz:1337/compositions/${props.compID}`) // url /?share=id
    //   .then(response => {
    //     // if(response.status === 404)
    //     // console.log(window.location.origin)
    //     if (response.ok) {
    //       return (response.json())
    //     } else {
    //       window.location.href = window.location.origin;
    //     }
    //   })
    //   .then(data => {
    //     return data.positions
    //   })
    // .catch(err => console.log(err))
    return fetch(`${process.env.REACT_APP_API_URL}/compositions/${props.compID}`)
      .then(response => {
        return (response.json())
      })
      .then(json => {
        // console.log(json,props.compID);
        // const comp = json.filter(obj => obj.shareId === props.compID)
        // alert(json);
        if (json) {
          return json.positions
        } else {
          window.location.href = window.location.origin;
        }
      }).catch(err => console.log(err))
  }
  // FETCH WITH PROMiSE
  function fetchArtists() {

    // return new Promise(resolve => {
    return fetch(`${process.env.REACT_APP_API_URL}/artists`)
      .then(response => response.json())
      .then(data => {
        return data
      })
  }

  function handleFetch() {
    // GET ALL FECTHED
    // console.log("FETCHING")
    if (props.share) {
      
      let artists = fetchArtists();
      let prePos = fetchPrePos()
      // alert("Fetching")
      Promise.all([ prePos, artists ]).then(resolve => {
        console.log(resolve)
        // alert("got")
        // console.log(resolve)
        makeLetters(resolve[ 1 ], resolve[ 0 ])
        setAllArtists(resolve[ 1 ])
      })
      
      // GET ALL ARTIST FECTHED ONLY
    } else {
      
      let artists = fetchArtists();
      Promise.all([ artists ]).then(resolve => {
        // console.log(resolve)
        makeLetters(resolve[ 0 ], [])
        setAllArtists(resolve[ 0 ])
      })

    }

  }
  useEffect(() => {
    console.log(prePos)
    if(prePos) {
      setPreComp(true)
      makeLetters(allArtists,prePos)
      setTimeout(() => {
        setPreComp(false)
      }, 400);

    }
  }, [prePos]);


  function makeLetters(dataArray, position) {
    const works = []

    dataArray.forEach(artist => {
      if (artist.audioTrack != null) { works.push(artist.audioTrack) }
      artist.webWorks.map(el => {
        if (el) { return el }
      }).forEach(img => works.push(img))
    })

    let letters = lettersArr.map((el, i) => {
      let Down = false;
      const whatWork = works.filter(el => {
        Down = el.letterIndex > 24 ? true : false;
        if (el.letterIndex === i || el.letterIndex === i + 25) {
          return el
        }
      })

      return (
        <Letter
          key={i}
          index={i}
          char={el}
          down={Down}
          data={whatWork}
          winHeight={winHeight}
          startPos={position[ i ]}
          zIndex={zIndex}
        // setPos={readLetterPositions}
        />
      )
    });
    setLetterComps(letters);
    // setPreComp(false)
  }

  function clickGo() {
    setPreComp(true);
    setPopUpActive(false);
    const a = [ ...document.getElementsByTagName("audio"),...document.getElementsByTagName("video") ]
    // const a = [ ...document.getElementsByTagName("video") ]
    a.forEach(a => {
      a.play()
      a.pause()
      if (a.dataset.isplaying) {
        console.log(a.dataset.isplaying)
      }
    })
    setTimeout(() => {
      setPrePopUp(false)
      
    }, 2000);
  }

  return (
    <div>
      {prePopUp ?
        <PopUp onClick={clickGo} closeButton={false} active={popUpActive} pos={[ winHeight / 2, window.innerWidth / 2 ]}>
          <p className="small">Click to start</p>
        </PopUp> : null}
      <div id="lettersContainer" style={{}} onMouseDown={() => setAboutActive(!aboutActive)}>
        {letterComps}
      </div>
      <Share />
      {/* {hasChanged ? <Share /> : null} */}
      <About prePos={prePos} setPrePos={setPrePos} letterActive={aboutActive} data={allArtists} />

    </div>

  )
}



export default App;
